<div class="content-wrapper container-xxl p-0">
  <div class="content-body" style="position: relative;">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div style="display: flex; flex-direction: row;">
      <div class="card" style="width: fit-content;">
        
<div class="row" style="padding: 10px;
position: relative;
justify-content: right;">
  <div class="col-md-6 col-12 d-flex justify-content-md-end">
    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
      <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
          class="form-control ml-25" (keyup)="filterUpdate($event)" /></label>
    </div>
  </div>
</div>
<ngx-datatable [rows]="plates" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="10"
(activate)="onActivate($event)"
  [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
  
  <ngx-datatable-column name="IMEI" prop="imei" [width]="250"></ngx-datatable-column>
  <ngx-datatable-column name="Plate" prop="plate" [width]="250"></ngx-datatable-column>
</ngx-datatable>
      </div>
      <div style="display: flex; flex-direction: column; margin-left: 24px;">
        <div class="form-group">
          <p style="margin-bottom: 4px;">Selected imei</p>
          <input type="text" class="form-control" id="readonlyInput" readonly="readonly" [value]="plate" />
        </div>
        <!-- <pre class="mt-2 p-1">Model: {{ basicDPdata | json }}</pre> -->
        <p style="margin-bottom: 4px;">Choose date</p>

        <!-- Basic Date Picker -->
        <form class="form-inline">
          <div class="form-group">
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="Basic Date Picker" [(ngModel)]="basicDPdata"
                ngbDatepicker #basicDP="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()" type="button"
                  rippleEffect></button>
              </div>
            </div>
          </div>
        </form>
        <br>
        <button type="button" class="btn btn-primary" (click)="findIt()" rippleEffect>Find</button>
        <!--/ Basic Date Picker -->

      </div>
    </div>
  </div>
</div>

