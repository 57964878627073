export const locale = {
    GR: {
        Details: 'Λεπτομέρειες',
        Details_d: 'Παράδοση παραγγελιών από τον αποθέτη στο πελάτη.',
        User_details: 'Στοιχεία Χρήστη',
        Available_for_delivery: 'Διαθέσιμος για παραδόσεις από όλους τους αποθέτες',
        Image: "Εικόνα",
        Upload: 'Μεταφόρτωση',
        Filters: 'Φίλτρα',
        Upload_File: 'Μεταφόρτωση Αρχείου',
        Check_your_columns_again: 'Ενημέρωση πεδίων!',
        Check_columns: 'Ενημέρωση Πεδίων!',
        Delivery_Date: 'Ημερομηνία Παράδοσης',
        Provider: 'Μεταφορική!',
        M_Provider: 'Επιλογή Μεταφορικής!',
        Send_with_Provider: 'Ενημέρωση μεταφορικής!',
        Mapping: 'Αντιστοίχιση Πεδίων',
        Field_Mapping: 'Αντιστοίχιση πεδίων',
        Geocode: 'Γεωκωδικοποίηση',
        WFM_History: 'WFM Ιστορικό',
        Uploaded_History: 'Μεταφορτωμένα Αρχεία',
        SUCCESS: 'Κατασταση',
        DATE: 'Ημερομηνια',
        ORDERS: 'Παραγγελιες',
        JSON: 'Παραγγελιες',
        APPROVED_BY: 'Μεταφορικη',
        DELIVERY_DATE: 'Ημερα Παραδοσης',
        UPDATED: 'Ενημερωθηκε',
        CREATED: 'Δημιουργηθηκε',
        COMPANY: 'Εταιρεια',
        Pending_actions: 'Ενέργειες',
        LOGO: 'Λογoτυπο',
        ACTION_A: 'Εμφανιση',
        ACTION_B: 'Αποδοχη',
        ACTION_C: 'Απορριψη',
        Export: 'Λήψη',
        Previous: 'Προηγούμενο',
        Submit: 'Αποστολή',
        Next: 'Επόμενο',
        entries: 'εγγραφές',
        Show: 'Δείξε',
        Back: 'Πίσω',
        Upload_Your_Orders: 'Μεταφόρτωσε τις παραγγελίες σου!',
        Upload_and_import: 'Μεταφόρτωσε και στείλε τις παραγγελίες σου στο WFM.',
        Upload_your_points: 'Μεταφόρτωσε τα σημεία σου και δες στο χάρτη τις διευθύνσεις.',
        History: 'Ιστορικότητα',
        Check_your_upload: 'Δες το ιστορικό σου!',
        Show_me: 'Δείξε μου',
        My_Profile: 'Το προφίλ μου',
        Check_your_profile: 'Δες τις πληροφορίες σου εδώ.',
        Check: 'Έλεγξε',
        See_your_pending: 'Ενέργειες που πρέπει να γίνουν.',
        Actions: 'Ενεργειες',
        Welcome_to_Synlog: 'Καλώς ήρθατε στο Revoil',
        Please_sign_in: 'Παρακαλώ συνδεθείτε με το λογαριασμό σας για να συνεχίσετε.',
        New_on_our_platform: 'Είστε νέος χρήστης;',
        Create_an_account: 'Δημιουργία λογαριασμού',
        or: 'ή',
        Forgot_Password: 'Ξέχασες τον κωδικό σου;',
        Phone_number: 'Αριθμός τηλεφώνου',
        is_required: 'είναι υποχρεωτικό',
        Company_Name: 'Όνομα εταιρείας',
        Company_Address: 'Διεύθυνση εταιρείας',
        Company_EIN: 'ΑΦΜ εταιρείας',
        Update: 'Ενημέρωση',
        Go_back: 'Επιστροφή',
        Reset: 'Επαναφορά',
        Sign_in: 'Σύνδεση',
        the_field: 'Το πεδίο ',
        Please_create: 'Παρακαλώ δημιουργήστε τον λογαριασμό σας για να συνεχίσετε.',
        Sign_up: 'Δημιουργία',
        have_an_account: 'Έχετε ήδη λογαριασμό;',
        Depositor: 'Αποθέτης',
        We_deliver_packages: 'Παραδίδουμε δέματα στους μεταφορείς.',
        Transporter: 'Μεταφορέας',
        Both: 'Αποθέτης & Μεταφορέας',
        buyers_by_ourselves: 'Παραδίδουμε τα δέματα εμείς στον πελάτη.',
        Logout: 'Αποσύνδεση',
        Dashboard: 'Αρχική',
        Geocoded_Address: 'Γεωκωδικοποιημένη',
        Job_Address: 'Εργασίας',
        Geocoded: 'Γεωκωδικοποιημένο',
        Not_Geocoded: 'Όχι Γεωκωδικοποιημένο',
        What_role: 'Ποιος είναι ο ρόλος σας;',
        Street_place: 'Οδός 12, Αθήνα',
        Tools: 'Εργαλεία',
        Import: 'Εισαγωγή',
        Type: 'Τύπος',
        Map: 'Αντιστοιχία',
        IoS: 'Μεταφορτώστε ή συγχρονίστε τα αρχεία σας μέσω του WFM',
        UfC: 'Μεταφόρτωση αρχείου από τον υπολογιστή',
        SETLF: 'Συγχρονίστε τα δεδομένα σας μέσω της πλατφόρμας ETL',
        Status: 'Κατάσταση',
        STATUS: 'Κατασταση',
        OrderStatus: 'Κατάσταση Παραγγελίας',
        Search: 'Αναζήτηση',
        RoutingDateRange: 'Εύρος ημερομηνιών δρομολόγησης',
        SubmissionDateRange: 'Εύρος ημερομηνιών καταχώρισης',
        OrganizationName: 'Ονομα εταιριας',
        Count: 'Πληθος',
    },
    EN: {
        Details: 'Details',
        Details_d: 'We deliver packages from sellers to buyers.',
        User_details: 'User Details',
        Available_for_delivery: 'Available for delivery from any depositor',
        Image: 'Image',
        Upload: 'Upload',
        Upload_File: 'Upload File',
        Filters: 'Filters',
        Check_your_columns_again: 'Check your columns again!',
        Check_columns: 'Check columns!',
        Delivery_Date: 'Delivery Date!',
        Provider: 'Provider!',
        Send_with_Provider: 'Send with Provider!',
        Mapping: 'Field Mapping',
        Field_Mapping: 'Field Mapping',
        Geocode: 'Geocode',
        WFM_History: 'Orders Monitoring',
        Uploaded_History: 'Past Imports',
        SUCCESS: 'SUCCESS',
        DATE: 'DATE',
        ORDERS: 'ORDERS',
        JSON: 'JSON',
        APPROVED_BY: 'Transporter',
        DELIVERY_DATE: 'DELIVERY DATE',
        UPDATED: 'UPDATED',
        CREATED: 'CREATED',
        COMPANY: 'COMPANY',
        Pending_actions: 'Pending actions',
        LOGO: 'LOGO',
        ACTION_A: 'Show Jobs',
        ACTION_B: 'Approve',
        ACTION_C: 'Reject',
        Export: 'Export',
        Previous: 'Previous',
        Submit: 'Submit',
        Next: 'Next',
        Back: 'Back',
        entries: 'entries',
        Show: 'Show',
        Upload_Your_Orders: 'Upload Your Orders!',
        Upload_and_import: 'Upload and import your orders to WFM',
        Upload_your_points: 'Upload your points to see it in our map and find their addresses',
        History: 'Orders Monitoring',
        Check_your_upload: 'Check your upload history here!',
        Show_me: 'Show me',
        My_Profile: 'My Profile',
        Check_your_profile: 'Check your profile here.',
        Check: 'Check',
        See_your_pending: 'See your pending actions here.',
        Actions: 'Actions',
        Welcome_to_Synlog: 'Welcome to Revoil',
        Please_sign_in: 'Please sign-in to your account and start the adventure',
        New_on_our_platform: 'New on our platform?',
        Create_an_account: 'Create an account',
        or: 'or',
        Forgot_Password: 'Forgot Password?',
        Phone_number: 'Phone number',
        is_required: 'is required',
        Company_Name: 'Company Name',
        Company_Address: 'Company Address',
        Company_EIN: 'Company VAT',
        Update: 'Update',
        Go_back: 'Go back',
        Reset: 'Reset',
        Sign_in: 'Sign in',
        the_field: '',
        Please_create: 'Please create an account and start the adventure',
        Sign_up: 'Sign up',
        have_an_account: 'Do you have an account?',
        Depositor: 'Depositor',
        We_deliver_packages: 'We deliver packages to the trasporters.',
        Transporter: 'Transporter',
        Both: 'Both',
        buyers_by_ourselves: 'We deliver the packages to our buyers by ourselves.',
        Logout: 'Logout',
        Dashboard: 'Dashboard',
        Geocoded_Address: 'Geocoded Address',
        Job_Address: 'Job Address',
        Geocoded: 'Geocoded',
        Not_Geocoded: 'Not Geocoded',
        What_role: 'What is your role?',
        Street_place: 'Street 12, Athens',
        Tools: 'Tools',
        Import: 'Import',
        Type: 'Type',
        Map: 'Map',
        IoS: 'Import or sync your data with WFM',
        UfC: 'Upload file from your computer',
        SETLF: 'Synchronize your data through ETL platform',
        Status: 'Status',
        OrderStatus: 'Order Status',
        Search: 'Search',
        RoutingDateRange: 'Routing Date Range',
        SubmissionDateRange: 'Submission Date Range',
        OrganizationName: 'Organization name',
        Count: 'Count',
    }
  }
  