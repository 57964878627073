import { CoreMenu } from '@core/types'

import { locale } from '../translate'

let user = (localStorage.getItem('revoil_user')) || null;
let array: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Telematics', //(localStorage.getItem('locale') || 'en')=='en' ? locale.EN.History : locale.GR.History,
    type: 'item',
    icon: 'codepen',
    url: 'dashboard'
  },
];


export const menu: CoreMenu[] = array;
