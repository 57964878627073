import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';


const routes = [
  {
    path: '',
    component: SampleComponent,
    data: { animation: '' }
  },
  {
    path: 'dashboard',
    component: SampleComponent,
    data: { animation: 'dashboard' }
  },
];

@NgModule({
  declarations: [SampleComponent, HomeComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule,
    CardSnippetModule,NgbModule,
    MatSelectModule,
    NgxDatatableModule, MatDialogModule, TranslateModule, CoreCommonModule, GoogleMapsModule, LeafletModule],
  exports: [SampleComponent, HomeComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SampleModule {}
