import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { timeout, catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  domain_local: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  headers = new HttpHeaders({
    'Content-Type': 'text/csv; charset=utf-8',
    'Authorization': `Bearer ${localStorage.getItem('revoil_token')}`
  })

  // ********************************** STORED DATA ***********************************
  arrayOfHeaders: Array<any> = [];

  jobsDetails: any = {};
  jobsDetailsHeader: any = {};
  
  // ********************************** GENERAL FUNCTION ***********************************
  
  loginProfile(params: any) {
    
    const formData = new FormData();
    formData.append('username', params.username);
    formData.append('password', params.password);

    return this.http.post(this.domain_local + `/login`, formData)
  }
  getPlates(params: any) {
    return this.http.get(this.domain_local + `/devices`, { headers: this.headers })
  }
  dowload(params: any) {
    //https://raw-data.navfleet.gr/350544506209561/2023/2/15
    // return this.http.get(this.domain_local + `/dowload/${params.action}`)
    return this.http.get(this.domain_local + '/' + params.action, { headers: this.headers })
  }

  // ********************************** GENERAL FUNCTION ***********************************
  callFunction(request:string, params: any){
    return new Promise(async (resolve, reject)=>{
      let response: any = {}

      if (request=='loginProfile'){ response = this.loginProfile(params) }
      else if (request=='getPlates'){ response = this.getPlates(params) }
      else if (request=='dowload'){ response = this.dowload(params) }
      

      response.pipe(timeout(1000*60*30), catchError(e=> {return of(null)})).subscribe(
        async (res: any) => {
          if(res==null){
            console.log(1, "Message!", "We cannot communicate with server! Timeout error!", "Close")
            resolve(null)
          }else{
            resolve(res)
          }
        },
        (er: any) => { console.error(er) }
      );
    })
  }
}
