import { Component, OnInit } from '@angular/core'
import * as L from 'leaflet'; 
import * as XLSX from 'xlsx';

import * as FileSaver from 'file-saver';
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'

import { CoreTranslationService } from '@core/services/translation.service'
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment'
import { locale } from '../../translate';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {
  public contentHeader: object;

  constructor(private _coreTranslationService: CoreTranslationService,
    private toastr: ToastrService, private service: AuthService,
    private router: Router) {

    }
    public basicDPdata: NgbDateStruct;

  plates: Array<any> = []
  Originalplates: Array<any> = []
  plate: string = "..."
  public basicSelectedOption: number = 10;
  public ColumnMode = ColumnMode;

  checkLogin(){
    let user = localStorage.getItem('revoil_user') || null;
    if(!user){
      this.router.navigate(['/pages/authentication/login-v2'])
    }else{
      this.continue_()
    }
  }

  async callPlates(){
    let res_:any = await this.service.callFunction('getPlates', {})
    this.plates = res_['devices']
    this.Originalplates = this.plates
  }
   filterUpdate(event) {
    const val = event.target.value.toLowerCase();

    this.plates = this.Originalplates.filter(o => {
      // console.log(o);
      return o.plate.toLowerCase().includes(val) || o.imei.toLowerCase().includes(val)
    })

    // update the rows
    // this.plates = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  continue_(){

    this.contentHeader = {
      headerTitle: "Metrilock",
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: "Raw logs"
          }
        ]
      }
    }
    this.callPlates()
  }

  select(header: string){
    this.plate = header;
  }

  onActivate(event: any){
    if(event.type=="click"){
      this.select(event.row.imei)
    }
  }

  async findIt(){


    if(this.plate == '...'){
      this.toastrCustomWarning("Imei is required!", "You have to choose a device.");
    }else if(this.basicDPdata == undefined){
      this.toastrCustomWarning("Date is required!", "You have to choose a date.");
    }else{
      let year = this.basicDPdata.year;
      let month = this.basicDPdata.month;
      let day = this.basicDPdata.day;
  
      
      let action = `${this.plate}/${year}/${month}/${day}`
      // await this.service.callFunction('dowload', {action: action})
      window.location.href=`https://raw-data.navfleet.gr/${action}/fa46b8f0-39e2-40bd-9a42-6849bfa8e51b`
    }
  }
  
  ngOnInit() {
    this.checkLogin()
  }
    // Custom Success
    toastrCustomSuccess(title: string, msg: string) {
      this.toastr.success(title, msg);
    }
    toastrCustomWarning(title: string, msg: string) {
      this.toastr.warning(title, msg);
    }
}
